export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        बोनस र पदोन्नति मा खण्ड अद्यावधिक गरिएको छ।
      </p>
      <p className="new__description">
        नक्सामा नजिकको शाखा, एटीएम वा टर्मिनलको स्थान थपिएको छ।
      </p>
      <p className="new__description">
        फेस आईडी र टच आईडीको लागि समर्थन थपियो।
      </p>
      <p className="new__description">
        केहि एन्ड्रोइड 10 प्रयोगकर्ताहरूले अनुभव गरिरहेको पुश सूचनाहरूको साथ
        समस्या समाधान गर्दछ।
      </p>
      <p className="new__description">
        एन्ड्रोइड प्रयोगकर्ताहरूको लागि सेटिङहरूमा पाठ ज्यादै सानो थियो भन्ने
        समस्या समाधान गरियो।
      </p>
    </div>
  );
};
